
import "@sparta.eportal-openbanking-devportal.modules/portal-module/dist/styles.css"


import spartaBootstrapUtility from "@sparta.global.utilities/sparta-bootstrap-utility/dist/es6.js"

import "@sparta.eportal-openbanking-devportal.modules/portal-module/dist/es6.js"


window.spaParams = window.spaParams || {};
window.spaParams.dynamicImportMap = {
  modules: [

  ],
}


// Add fingerprint spa-map.json into spaParams

// Initialize page
;(async function() {
  console.log('INIT PAGE')
  console.log('before - execute boot functions')
  await spartaBootstrapUtility.init(arguments);
  console.log('after - execute boot functions')
})();
